import { customElement, property } from 'lit/decorators.js';
import { getProjectNumberFormatted } from '../../dealer-franchisee/projects/data/project-helper-functions';
import { html } from 'lit';
import { ProjectSummaryTable, ProjectSummaryTableOptions } from './project-list-view';
import { staleIcon } from '../../../webmodule-common/other/webmodule-common';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { ViewProjectSummary } from '../../api/dealer-api-interface-project';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

export interface ProjectPickerOptions extends ProjectSummaryTableOptions {
  onProjectSelected?: (projectSummary: ViewProjectSummary) => void;
  selectedProject?: ViewProjectSummary;
  clientId?: string;
}

@customElement('wm-projectsummarypickerbrowser')
export class ProjectPickerBrowser extends ProjectSummaryTable {
  onProjectSelected?: (projectSummary: ViewProjectSummary) => void;

  @property({ type: Object })
  selectedProject?: ViewProjectSummary;

  constructor(options: ProjectPickerOptions) {
    super(options);
    this.onProjectSelected = options.onProjectSelected;
    this.selectedProject = options.selectedProject;
    this.clickrows = true;
    this.selectMode = 'single';
  }

  override isDataLoadDelayed(): boolean {
    return false;
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    const checkTemplate = (value: ViewProjectSummary) => {
      const checkClass = value.id === this.selectedProject?.id ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle';
      return html`<span class="ticket-owner-check">
        <i class="fa ${checkClass}"></i>
      </span>`;
    };

    return [
      {
        title: tlang``,
        fieldName: 'id',
        sortable: true,
        classes: 'colpx-90 colpxmax-180 project-picker-id',
        displayValue: (_table: WebModuleLitTable, item: ViewProjectSummary) => {
          return checkTemplate(item);
        }
      },
      {
        title: tlang`%%project%% No.`,
        fieldName: 'number',
        sortable: true,
        classes: 'colpx-90 colpxmax-180 project-picker-number',
        displayValue: (_table: WebModuleLitTable, item: ViewProjectSummary) => {
          return this.stale(item)
            ? // eslint-disable-next-line @typescript-eslint/no-base-to-string
              `${staleIcon(false)}${getProjectNumberFormatted(item)}`
            : getProjectNumberFormatted(item);
        }
      },
      {
        title: tlang`%%project%% Title`,
        fieldName: 'title',
        sortable: true,
        classes: 'colpx-400 colpxmax-200 project-picker-title',
        displayValue: (_table: WebModuleLitTable, item: ViewProjectSummary) => {
          return item.title;
        }
      },
      {
        title: tlang`%%client%%`,
        fieldName: 'clientId',
        sortable: false,
        classes: 'colpx-200 colpxmax-100 project-picker-client',
        displayValue: (_table: WebModuleLitTable, item: ViewProjectSummary) => {
          return this.getClientDisplayValue(item.clientId);
        }
      }
    ];
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async rowClicked(e: CustomEvent<{ table: WebModuleLitTable; item: ViewProjectSummary }>): Promise<void> {
    e.stopImmediatePropagation();
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/require-await
    this.eventHandler(async () => {
      this.onProjectSelected?.(e.detail.item);
      this.selectedProject = e.detail.item;
    });
  }
}
