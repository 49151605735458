import {
  checkPATTokenOnURL,
  DealerApiCommunications,
  performInitialLoginAttempt,
  refreshUserToken,
  startTokenRefreshTimer
} from '../../webmodule-common/other/api/dealer-api-communications';
import { information } from '../../webmodule-common/other/ui/modal-option';
import { isCynclyStaff } from '../../webmodule-common/other/currentuser-claims';
import { tlang } from '../../webmodule-common/other/language/lang';
import { userDataStore } from '../dealer-franchisee/common/current-user-data-store';
import { getCurrentUser } from '../../webmodule-common/other/api/current-user';

export async function performPageLoadLoginVerifications(updateNote: (message: string) => void) {
  // if we resolved a PAT token, then we just force switched authentication and can exit
  let valid = false;
  if (await checkPATTokenOnURL()) {
    valid = true;
  } else {
    //This is going to go to the api and verify the user token is valid
    //and if it is, it will also set the security roles of the user
    valid = await DealerApiCommunications.verifyUserAndSetSecurityClaims('api/LicenseServer/verifyuser');
  }
  if (!valid) {
    if (window.location.pathname !== '/login') window.location.href = '/login';

    await performInitialLoginAttempt('api/LicenseServer/verifyuser');
    valid = getCurrentUser() !== null;
  }

  if (valid) {
    const user = await refreshUserToken(updateNote);
    if (user) {
      updateNote('Fetching User Settings');
      await userDataStore.loadCoreDetails();

      const usingPAT = localStorage.getItem('PAT-in-use') !== null;
      if (usingPAT) {
        const title = (user as any).tenantName ? `${(user as any).tenantName}` : tlang`Support Access Granted`;
        if (!isCynclyStaff())
          await information(
            tlang`You are accessing this system using a temporary access login. please logoff when complete`,
            title
          );
      } else {
        startTokenRefreshTimer();
      }
      return true;
    } else return false;
  }
  return false;
}

export async function dealerVerifyUser(updateNote: (message: string) => void) {
  return await performPageLoadLoginVerifications(updateNote);
}
