import { v6BootLoader, v6Events, v6SupportsVersion, v6VersionMap } from '../../v6config/v6config';
import { StringDictionary } from '../../../webmodule-common/interop/types/misc';

interface IV6DebugInformation {
  name: string;
  data: StringDictionary;
}

let _v6DebugInformation: IV6DebugInformation[] = [];

export async function clearAllV6DebugInformation(name?: string) {
  if (name) {
    _v6DebugInformation = _v6DebugInformation.filter(x => x.name !== name);
  } else _v6DebugInformation = [];

  if (!(await v6BootLoader())) return;
  if (v6SupportsVersion(v6VersionMap.hasAdditionalInfo)) v6Events().setApiAdditionalData(getV6DebugInformation());
}

export async function addV6DebugInformation(name: string, data: StringDictionary) {
  _v6DebugInformation = [..._v6DebugInformation.filter(x => x.name !== name), { name, data }];
  if (!(await v6BootLoader())) return;
  if (v6SupportsVersion(v6VersionMap.hasAdditionalInfo)) v6Events().setApiAdditionalData(getV6DebugInformation());
}

export function getV6DebugInformation(): StringDictionary | undefined {
  if (!_v6DebugInformation || _v6DebugInformation.length === 0) return undefined;
  return Object.assign({}, ..._v6DebugInformation.map(info => info.data));
}
