import {
  BranchQuoteSupportItemType,
  BranchQuoteSupportOwnerFilter,
  BranchQuoteSupportStatus,
  QuoteItemConversation,
  ResultGetBranchQuoteSupportInbox,
  ViewBranchQuoteSupportInboxItem
} from '../../../api/dealer-api-interface-franchisee';
import {
  buildModifiedTime,
  getSupportStatusBadge,
  getSupportTypeDisplayValue
} from '../../conversation/conversation-helper';
import { cache } from '../../cache-impl/cache-registry';
import { customElement, property, state } from 'lit/decorators.js';
import {
  DataBinding,
  DataTracker,
  FieldType,
  FormInputAssistant
} from '../../../../webmodule-common/other/webmodule-common';
import { EventSnippet } from '../../../../webmodule-common/interop/webmodule-interop';
import { getApiFactory } from '../../../api/api-injector';
import { getCurrentUser } from '../../../../webmodule-common/other/api/current-user';
import { getQuoteNumberFormatted, getQuoteTitleStr } from '../../../quotes/data/quote-helper-functions';
import { html, TemplateResult } from 'lit';
import { LitTableWrapper } from '../../../../webmodule-common/other/ui/littable-view';
import { PageManager } from '../../../../webmodule-common/other/ui/page-control';
import { RequestPage, ResultPaginated } from '../../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver';
import { resourceQuote } from '../../../quotes/ui/launcher';
import {
  serverDateTimeToLocal,
  serverDateTimeToLocalDateTime
} from '../../../../webmodule-common/other/datetime-converter';
import { tlang } from '../../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../common/current-user-data-store';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../../webmodule-common/components/src/components/table/lit-table';

export interface QuoteSupportInboxTableOptions {
  tabName: string;
  caption: EventSnippet;
  statusFilter: BranchQuoteSupportStatus;
  typeFilter: BranchQuoteSupportItemType;
  showResolvedColumns: boolean;
}

@customElement('wm-branchquotesupportinboxviewtab')
export class QuoteSupportInboxTable extends LitTableWrapper<ViewBranchQuoteSupportInboxItem> {
  franchiseeApi = getApiFactory().franchisee();
  quoteCache = cache().quote;
  clientCache = cache().client;
  userCache = cache().userProfile;

  options: QuoteSupportInboxTableOptions;

  dataIsStale = false;
  private titleFilter: string | null = null;

  private dataTracker: DataTracker;

  @state()
  private _conversationLinks: QuoteItemConversation[] | undefined;
  private get conversationLinks(): QuoteItemConversation[] | undefined {
    return this._conversationLinks;
  }
  private set conversationLinks(value: QuoteItemConversation[] | undefined) {
    this._conversationLinks = value;
  }

  @property({ type: Number })
  public ticketOwnerFilter: BranchQuoteSupportOwnerFilter = BranchQuoteSupportOwnerFilter.All;

  constructor(options: QuoteSupportInboxTableOptions) {
    super();
    this.options = options;

    this.dataTracker = new DataTracker(new DataBinding(this));

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    addField('ticketOwnerFilter', FieldType.int, false);
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewBranchQuoteSupportInboxItem>> {
    const result = await this.franchiseeApi.getBranchQuoteSupportInbox({
      //   branchId: userDataStore.defaultBranch.id
      filter: this.titleFilter,
      requestPage: {
        pageIndex: request.pageIndex,
        pageSize: request.pageSize
      },
      sortAsc: true,
      sortField: '',
      statusFilter: this.options.statusFilter,
      ticketOwnerFilter: this.ticketOwnerFilter,
      typeFilter: this.options.typeFilter,
      branchId: userDataStore.defaultBranch.id
    });
    console.log(result);
    if (result) {
      const prefetch = this.getPrefetched(result);
      await Promise.all(prefetch);

      this.conversationLinks = result.conversationLinks;
      return result.items;
    }
    this.conversationLinks = [];
    return {
      count: 0,
      pageCount: 0,
      pageIndex: 0,
      pageSize: this.pageLength(),
      results: []
    };
  }

  getPrefetched(result: ResultGetBranchQuoteSupportInbox): Promise<void>[] {
    const quoteIds = result.items.results.map(i => i.branchQuoteId);
    const authorIds = result.items.results.map(i => i.createdUserId);

    return [this.quoteCache.preFetch(quoteIds), this.userCache.preFetch(authorIds)];
  }

  getColumns(): WebModuleLitTableColumnDef[] {
    const columns: WebModuleLitTableColumnDef[] = [];

    columns.push({
      title: tlang`%%quote%% No.`,
      fieldName: 'quoteNumber',
      sortable: true,
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const quote = this.quoteCache.getLocal(item.branchQuoteId);
        const quoteNumber = getQuoteNumberFormatted(quote?.data.quoteSummary);
        return this.getQuoteLink(item, quoteNumber);
      }
    });
    columns.push({
      title: tlang`%%quote%% Title`,
      fieldName: 'title',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const quote = this.quoteCache.getLocal(item.branchQuoteId);
        return this.getQuoteLink(item, getQuoteTitleStr(quote?.data.quoteSummary));
      }
    });
    columns.push({
      title: tlang`%%client%%`,
      fieldName: 'clientName',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return `${item.clientName}`;
      }
    });
    columns.push({
      title: tlang`Ticket Title`,
      fieldName: 'ticketTitle',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const hasConversation =
          item.type === BranchQuoteSupportItemType.EngineeredToOrder ||
          item.type === BranchQuoteSupportItemType.QuoteAssistanceRequest;
        return hasConversation ? this.getConversationLink(item, item.subject) : item.subject;
      }
    });
    columns.push({
      title: tlang`%%author%%`,
      fieldName: 'author',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const author = this.userCache.getLocal(item.createdUserId);
        return `${author?.data.friendlyName ?? ''}`;
      }
    });
    columns.push({
      title: tlang`Request Type`,
      fieldName: 'requestType',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return getSupportTypeDisplayValue(item.type);
      }
    });
    columns.push({
      title: tlang`Status`,
      fieldName: 'status',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return getSupportStatusBadge(item.status);
      }
    });
    columns.push({
      title: tlang`Date Submitted`,
      fieldName: 'dateCreated',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        return serverDateTimeToLocalDateTime(item.dateCreated).toLocaleString();
      }
    });
    columns.push({
      title: tlang`Last Modified`,
      fieldName: 'lastModified',
      classes: '',
      displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
        const label = cache().userProfile.getLocalData(item.lastModifiedUserId)?.friendlyName ?? '';
        const dateValue = buildModifiedTime(serverDateTimeToLocalDateTime(item.lastModified));
        return html`<span class="lastmodified-date me-2">${dateValue}</span>
          <span class="lastmodified-user">${label}</span>`;
      }
    });
    if (this.options.showResolvedColumns) {
      columns.push({
        title: tlang`Date Resolved`,
        fieldName: 'dateResolved',
        classes: '',
        displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
          const resolvedDate =
            item.status === BranchQuoteSupportStatus.Resolved
              ? serverDateTimeToLocal(item.lastModified).toLocaleDateString()
              : '-';

          return resolvedDate;
        }
      });
      columns.push({
        title: tlang`Resolution`,
        fieldName: 'lastModified',
        classes: '',
        displayValue: (_table: WebModuleLitTable, item: ViewBranchQuoteSupportInboxItem) => {
          const resolvedDate =
            item.status === BranchQuoteSupportStatus.Resolved
              ? buildModifiedTime(serverDateTimeToLocalDateTime(item.lastModified))
              : '-';
          return resolvedDate;
        }
      });
    }

    return columns;
  }

  getQuoteLink(item: ViewBranchQuoteSupportInboxItem, value: string | TemplateResult): TemplateResult {
    return html`<a
      class="quote-link"
      href="${resolveURL(resourceQuote, item.branchQuoteId)}"
      quoteid="${item.branchQuoteId}"
      >${value}</a
    >`;
  }

  getConversationLink(item: ViewBranchQuoteSupportInboxItem, value: string | TemplateResult): TemplateResult {
    const supportFragment =
      item.type === BranchQuoteSupportItemType.EngineeredToOrder ||
      item.type === BranchQuoteSupportItemType.QuoteAssistanceRequest
        ? `support_${item.conversationId}`
        : null;
    return html`<a
      class="quote-link"
      href="${resolveURL(resourceQuote, item.branchQuoteId, supportFragment)}"
      quoteid="${item.branchQuoteId}"
      >${value}</a
    >`;
  }

  override enableFiltering(): boolean {
    return true;
  }

  override updateFilter(_searchTerm: string | null) {
    this.titleFilter = _searchTerm;
    this.updateCurrentTicketOwnerFilter();
  }

  override enableAdvancedFiltering(): boolean {
    return true;
  }

  override advancedFilterTemplate(): TemplateResult {
    const forms = new FormInputAssistant(this.dataTracker);

    const ownerEnumToString = (owner: BranchQuoteSupportOwnerFilter) => {
      if (owner === BranchQuoteSupportOwnerFilter.Mine) return tlang`My Tickets`;
      else return tlang`All Tickets`;
    };

    return html` ${forms.radioGroup('ticketOwnerFilter', BranchQuoteSupportOwnerFilter, tlang`Type`, {
      map: ownerEnumToString,
      events: {
        change: this.ticketOwnerFilterChanged
      }
    })}`;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  override async rowClicked(
    details: CustomEvent<{ table: WebModuleLitTable; item: ViewBranchQuoteSupportInboxItem }>
  ): Promise<void> {
    this.dispatchCustom('open-ticket', {
      item: details.detail.item,
      links: this.getConversationLinks(details.detail.item.conversationId),
      handled: false
    });
  }

  getConversationLinks(conversationId: string) {
    return this.conversationLinks?.filter(x => x.conversationId === conversationId) ?? [];
  }

  updateCurrentTicketOwnerFilter() {
    const ticketOwnerFilter = this.dataTracker.getEditorValue('ticketOwnerFilter');
    this.ticketOwnerFilter = ticketOwnerFilter as BranchQuoteSupportOwnerFilter;
  }

  ticketOwnerFilterChanged: (e: Event) => void = () => {
    this.updateCurrentTicketOwnerFilter();
  };

  public createPageManager(): PageManager {
    return {
      caption: this.options.caption,
      canClose: () => Promise.resolve(false),
      canLeave: () => Promise.resolve(true),
      content: () => this,
      hasDelete: () => false,
      onEnter: async () => {
        //enable data load the first time we enter the tab, but not before
        this.delayedDataLoad = false;
        // not-awaiting on purpose, for faster page switch. safe to do
        if (this.dataIsStale) this.refreshData();
      },
      data: this,
      pageFragment: this.options.tabName
    };
  }
}
