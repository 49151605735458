import { customElement } from 'lit/decorators.js';
import { V6QuoteSupplierDefaultsView } from '../../../../quotes/v6/v6-quote-supplier-defaults-view';
import { V6FranchiseeQuoteProviderData } from '../../data/franchisee-quote-provider-data';

@customElement('wm-franchiseev6quotesupplierdefaultsview')
export class FranchiseeV6QuoteSupplierDefaultsView extends V6QuoteSupplierDefaultsView {
  protected getQuoteDefaultOptions(): unknown | null {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.defaultOptions ?? null;
  }

  protected getQuoteGlobalAttributesMasterSet(): unknown | null {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.globalAttributesMasterSet ?? null;
  }

  protected setQuoteGlobalAttributesMasterSet(options: unknown) {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.globalAttributesMasterSet = options;
  }

  protected setQuoteDefaultOptions(options: unknown) {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.defaultOptions = options;
  }

  protected getQuoteAttributeUpdateHistory(): unknown | null {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.quoteAttributeUpdateHistory ?? null;
  }

  protected setQuoteAttributeUpdateHistory(options: unknown) {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.quoteAttributeUpdateHistory = options;
  }

  protected getQuoteIGUs(): unknown /* V6QuoteIGU[] */ {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.igu ?? [];
  }
}
