// eslint-disable-next-line import/named
import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../webmodule-common/other/ui/modal-confirmation';
import { asMarkdownTemplate } from '../../../webmodule-common/other/general/markdown';
import { customElement, query } from 'lit/decorators.js';
import { FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { FormInputAssistantWithTracker } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getSupplierVersion } from '../data/v6/helper-functions';
import { html } from 'lit';
import { isDebugMode } from '../../../webmodule-common/other/debug';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { isSomething } from '../../../webmodule-common/other/general/nothing';
import { lang, tlang } from '../../../webmodule-common/other/language/lang';
import { Message, MessageType } from '../../../webmodule-common/other/messages';
import { ModalDialog } from '../../../webmodule-common/other/ui/modal-base';
import { processQuoteItemState } from '../data/quote-helper-functions';
import { showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { waitPatiently } from '../../../webmodule-common/other/ui/modal-spinner';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/components/table/lit-table';
import type { EventTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import type { QuoteContainerManager } from '../data/quote-container';
import type { QuoteItemContainer } from '../data/quote-item-container';
import type { TemplateResult } from 'lit';

export async function validateOutOfDateQuoteItems(
  quoteManager: QuoteContainerManager,
  handler: OutOfDateQuoteItemHandler
) {
  await new ValidateOutOfDDateQuoteItemsModal(quoteManager, handler).showModal();
}

/**
 * a list of properties that we need to apply to a quote completedItem before doing an update.
 */
interface ItemToProcess {
  quoteItemId: string;
}

/**
 * tracking completedItem for keeping a record if which items are selected to update
 */
interface UITargetItemChecked {
  targetItem: QuoteItemContainer;
  checked: boolean;
}

/**
 * ui tracking to render and note the quote items that are updated, and what the status of them was
 */
export interface QuoteItemProcessingCompleteItem {
  quoteItemContainer: QuoteItemContainer;
  //TODO-Add an expansion view to show any validation
  validation: Message[];
  error: string;
  oldPrice: number;
  newPrice: number;
  completionStatus: string;
}

/**
 * helper interface to keep the properties together for processing
 */
interface DataProcessingInformation {
  //the client has started processing
  processing: boolean;
  //sucessfully completed processing
  processingComplete: boolean;
  //cancel was requested
  cancelling: boolean;
  //the list of items to process for this job
  itemsToProcess: ItemToProcess[];
  //the current completedItem being processed
  currentItemToProcess: ItemToProcess | null;
  //the current state of the current completedItem
  currentItemState: string;
  //list of completed items
  completedItems: QuoteItemProcessingCompleteItem[];
}

export interface OutOfDateQuoteItemHandler {
  finalProcessing: (qm: QuoteContainerManager) => Promise<boolean>;
  isOutOfDate: (qic: QuoteItemContainer) => boolean;
  isValidatingItem: (qic: QuoteItemContainer) => boolean;
  displayInfo: (qic: QuoteItemContainer) => string;
  processItemEvent: (
    qm: QuoteContainerManager,
    qic: QuoteItemContainer,
    completeItem: QuoteItemProcessingCompleteItem,
    progress: (caption: string) => Promise<void>
  ) => Promise<void>;
  displayInfoTitle: string;
}

@customElement('wm-validateoutofddatequoteitemsmodal')
class ValidateOutOfDDateQuoteItemsModal extends ModalDialog {
  currentProcess: Promise<void> | null = null;
  processingInfo: DataProcessingInformation = {
    processing: false,
    cancelling: false,
    itemsToProcess: [],
    currentItemToProcess: null,
    currentItemState: '',
    completedItems: [],
    processingComplete: false
  };
  targetChecked: UITargetItemChecked[] = [];
  handler: OutOfDateQuoteItemHandler;
  quoteManager: QuoteContainerManager;
  outOfDateItems: QuoteItemContainer[] = [];
  runningFinalProcessing = false;
  private forms: FormInputAssistantWithTracker;
  @query('#table') table?: WebModuleLitTable;

  constructor(quoteManager: QuoteContainerManager, handler: OutOfDateQuoteItemHandler) {
    super();
    this.handler = handler;
    this.quoteManager = quoteManager;
    if (quoteManager.container.items)
      for (const quoteItem of quoteManager.container.items) {
        const item = quoteManager.quoteItemContainer(quoteItem.id);
        if (this.handler.isValidatingItem(item) && this.isOutOfDate(item)) {
          this.outOfDateItems.push(item);
          this.quoteItemChecked(item, true);
        }
      }

    this.forms = new FormInputAssistantWithTracker(
      this.ui,
      () => this.isRunning,
      x => {
        x.immediateBindingUpdate = true;
      }
    );
    this.forms.addDynamic(
      'allChecked',
      FieldType.boolean,
      () => this.allChecked,
      (value: boolean) => {
        this.outOfDateItems.forEach(x => this.quoteItemChecked(x, value));
        this.requestUpdate(); //no wait
      }
    );
    for (const item of this.outOfDateItems) {
      this.forms.addDynamic(
        `marked-${item.item.id}`,
        FieldType.boolean,
        () => this.quoteItemChecked(item),
        (v: boolean) => {
          this.quoteItemChecked(item, v);
          this.requestUpdate();
        }
      );
      this.forms.addDynamic(
        `title-${item.item.id}`,
        FieldType.string,
        () => `${this.quoteManager.itemPosition(item.item.id)} ${item.item.title} ${item.item.description}`
      );
      this.forms.addDynamic(`version-${item.item.id}`, FieldType.string, () =>
        this.displayVal(this.getQuoteItemDisplayValue(item), '')
      );
      this.forms.addDynamic(`status-${item.item.id}`, FieldType.int, () =>
        this.displayVal(this.getQuoteItemDisplayValue(item), '')
      );
    }

    this.tableColumns = [
      {
        title: () =>
          html`<div class="ms-1 webmodule-checkbox-white-surround">${this.forms.checkbox(`allChecked`)}</div>`,
        fieldName: 'marked',
        classes: 'colpxmax-50 validation-col-check',
        displayValue: (_table: WebModuleLitTable, item: QuoteItemContainer) => {
          return this.forms.checkbox(`marked-${item.item.id}`);
        }
      },
      {
        title: tlang`Item #`,
        fieldName: 'frame',
        classes: 'colpxmax-60',
        displayValue: (_table: WebModuleLitTable, item: QuoteItemContainer) => {
          return html`<span class="fw-bolder">${this.quoteManager.itemPosition(item.item.id)}</span>`;
        }
      },
      {
        title: tlang`%%frame-title%% and Description`,
        fieldName: 'frame',
        classes: 'colpx-300',
        displayValue: (_table: WebModuleLitTable, item: QuoteItemContainer) => {
          return html` <div class="alignment-left">
              <div class="fw-bolder">${item.item.title}</div>
              <div>${item.item.description}</div>
          </div>`;
        }
      },
      {
        title: tlang`Version`,
        fieldName: 'version',
        classes: 'colpxmax-300',
        displayValue: (_table: WebModuleLitTable, item: QuoteItemContainer) => {
          return this.forms.dataTracker.getObjectDisplayValue(`version-${item.item.id}`) ?? '';
        }
      },

      {
        title: tlang`Status`,
        fieldName: 'status',
        classes: 'colpxmax-150 validation-col-status',
        displayValue: (_table: WebModuleLitTable, item: QuoteItemContainer) => {
          return this.getItemBadge(item);
        }
      }
    ];
  }

  get allChecked(): boolean | null {
    const checkedCount = this.outOfDateItems.filter(
      x => this.targetChecked.find(y => y.targetItem.item.id === x.item.id)?.checked
    ).length;
    const totalCount = this.outOfDateItems.length;
    if (checkedCount === 0) return false;
    if (checkedCount === totalCount) return true;
    return null;
  }

  protected get modalSize() {
    return 'modal-xl';
  }

  protected get modalClasses(): string {
    return 'modal-dialog modal-dialog-scrollable v6-quote-item-validation-modal';
  }

  private get hasSupplierPriceAdjustments(): boolean {
    return this.quoteManager.container.itemPrices?.some(x => x.supplierPriceAdjustment !== 0) ?? false;
  }

  isOutOfDate(item: QuoteItemContainer): boolean {
    return this.quoteManager.isBuyInDataLocked
      ? this.handler.isOutOfDate(item)
      : this.handler.isOutOfDate(item) || !this.isBuyInPriceCurrent(item);
  }

  /**
   *
   * @param sourceItem the source property
   * @returns a template that displays all targets that dont match, that can be checked to change
   */
  private tableColumns: WebModuleLitTableColumnDef[];

  outOfDateItemsTemplate2(sourceItem: QuoteItemContainer[]): TemplateResult {
    return html` <webmodule-lit-table
      id="table"
      class="data-table"
      .rowClass=${'row'}
      .colClass=${'col'}
      .data=${[...sourceItem]}
      .columns=${this.tableColumns}
      pageLength=${10000}
      ?clickrows=${false}
    >
    </webmodule-lit-table>`;
  }

  getQuoteItemDisplayValue(qic: QuoteItemContainer): string | null | undefined {
    return this.handler.displayInfo(qic);
  }

  get isRunning(): boolean {
    return this.processingInfo.processing || isSomething(this.currentProcess) || this.processingInfo.processingComplete;
  }

  async startProcessing(): Promise<void> {
    this.processingInfo.itemsToProcess = [];
    this.targetChecked.filter(x => x.checked).map(x => this.getItemToProcess(x.targetItem));

    if (this.processingInfo.itemsToProcess.length > 0) {
      this.processingInfo.itemsToProcess.sort((a, b) => {
        //reverse sort so that popping off the queue takes the first index
        const aVal = this.quoteManager.itemPosition(a.quoteItemId) ?? -1;
        const bVal = this.quoteManager.itemPosition(b.quoteItemId) ?? -1;
        return bVal - aVal;
      });
      this.processingInfo.cancelling = false;
      this.processingInfo.processing = true;
      this.currentProcess = this.createProcessPromise();
      try {
        await this.currentProcess;
      } catch (e) {
        await showDevelopmentError(e as Error);
      }
    } else {
      this.processingInfo.cancelling = false;
      this.processingInfo.processing = false;
      this.currentProcess = null;
    }
  }

  async createProcessPromise(): Promise<void> {
    await this.processAllItems();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async processAllItems(): Promise<void> {
    if (this.quoteManager.quoteChanged()) await this.quoteManager.saveQuote();

    while (this.processingInfo.itemsToProcess.length > 0 && !this.processingInfo.cancelling) {
      await this.processNextItem();
    }
    this.runningFinalProcessing = true;
    try {
      this.requestUpdate();
      await this.finalProcessing();
    } finally {
      this.runningFinalProcessing = false;
    }
    this.currentProcess = null;
    this.processingInfo.processing = false;
    this.processingInfo.processingComplete = true;
    this.requestUpdate();
  }

  async finalProcessing() {
    return await this.handler.finalProcessing(this.quoteManager);
  }

  private getItemBadge(qic: QuoteItemContainer): TemplateResult {
    let badgevariant = 'neutral';
    let badgeText = tlang`Not Processed`;
    let completedItem: QuoteItemProcessingCompleteItem | undefined = undefined;
    if (this.isRunning) {
      if (this.processingInfo.currentItemToProcess?.quoteItemId === qic.item.id) {
        badgevariant = 'primary';
        badgeText = tlang`Updating`;
      } else {
        completedItem = this.processingInfo.completedItems.find(x => x.quoteItemContainer.item.id === qic.item.id);
        if (completedItem) {
          if (completedItem.completionStatus === processQuoteItemState.completed) {
            badgevariant = 'success';
            badgeText = tlang`Saved`;
          } else if (completedItem.completionStatus === processQuoteItemState.errors) {
            badgevariant = 'danger';
            badgeText = tlang`Error`;
          }
        }
      }
    }

    const priceVarianceTemplate = () => {
      if (!completedItem) return html``;
      if (completedItem.newPrice != completedItem.oldPrice)
        return html`<i class="fa-solid fa-comment-dollar text-info"></i>`;
      else return html``;
    };

    //if any errors occured, indicate visually what happened.
    const errorTemplate = () => {
      if (!completedItem) return html``;
      if (completedItem.completionStatus !== processQuoteItemState.completed || completedItem.error !== '')
        return html`<i class="fa-solid fa-circle-exclamation text-danger"></i>`;
      else return html``;
    };
    //if there are any validation issues after the change, show a warning.
    //maybe have a click button to expand and show the issues, but they can also go open the completedItem
    const validationTemplate = () => {
      if (!completedItem) return html``;
      if (completedItem.validation.length > 0) return html`<i class="fa-solid fa-triangle-exclamation text-info"></i>`;
      else return html``;
    };
    return html`
      <div class="status">
        <webmodule-badge .variant=${badgevariant}
          ><span>${badgeText}</span></webmodule-badge
        >
        ${priceVarianceTemplate()} ${errorTemplate()} ${validationTemplate()}
      </div>
    `;
  }

  /**
   * process a quote completedItem with artificial delays to throttle.
   * @returns
   */
  async processNextItem(): Promise<void> {
    this.processingInfo.currentItemToProcess = this.processingInfo.itemsToProcess.pop() ?? null;
    if (!this.processingInfo.currentItemToProcess) return;
    const item = this.processingInfo.currentItemToProcess;

    //get the quote completedItem that we need to update as a forced refresh of the live data
    //due to updates, the data we have may no longer be valid due to server updates
    const itemContainer = this.quoteManager.quoteItemContainer(item.quoteItemId);

    //prepare the completed completedItem for the ui
    const completeItem: QuoteItemProcessingCompleteItem = {
      quoteItemContainer: itemContainer,
      oldPrice: itemContainer.price.quantityCost,
      newPrice: 0,
      validation: [],
      error: '',
      completionStatus: processQuoteItemState.errors
    };

    try {
      this.requestUpdate();
      if (!isDebugMode())
        //we want to stop people throttling the system by adding some delays
        await this.sleep(500);

      await this.handler.processItemEvent(this.quoteManager, itemContainer, completeItem, async (caption: string) => {
        this.processingInfo.currentItemState = caption;
        this.requestUpdate();
      });
      this.processingInfo.currentItemState = processQuoteItemState.completed;
      this.requestUpdate();
      if (!isDebugMode()) await this.sleep(1500);
      if (this.processingInfo.currentItemState === processQuoteItemState.completed)
        if (completeItem.completionStatus !== processQuoteItemState.errors)
          completeItem.completionStatus = processQuoteItemState.completed;
    } finally {
      this.processingInfo.completedItems?.push(completeItem);
      this.processingInfo.currentItemToProcess = null;

      if (this.table) {
        if (this.needsExtendedDisplay(completeItem)) {
          this.table.addExtension(
            completeItem.quoteItemContainer,
            this._extendedDetailsTemplateGenerator(completeItem)
          );
        }
      }

      this.requestUpdate();
    }
  }

  private _extendedDetailsTemplateGenerator = completeItem => () => this.getExtendedDetails(completeItem);

  private getExtendedDetails(completeItem: QuoteItemProcessingCompleteItem): TemplateResult {
    const validationsTemplate = (validationType: MessageType, cssClass: string): TemplateResult[] | TemplateResult => {
      const stuff = completeItem?.validation?.filter(x => x.messageType === validationType) ?? [];

      //inject price change warning if the price has changed
      if (validationType === MessageType.Warning) {
        if (completeItem.newPrice !== completeItem.oldPrice) {
          stuff.push({
            messageType: MessageType.Warning,
            message: tlang`Price has changed from ${completeItem.oldPrice} to ${completeItem.newPrice}`
          });
        }
      }

      //inject error message if there is an error
      if (validationType === MessageType.Error) {
        if (!isEmptyOrSpace(completeItem.error)) {
          stuff.push({ messageType: MessageType.Error, message: completeItem.error });
        }
      }

      if (stuff.length === 0) return html``;
      const rows = stuff.map(issue => {
        return html` <li class="list-group-item ${cssClass}">${lang(issue.message ?? '')}</li>`;
      });
      return html`
        <ul class="list-group">
          ${rows}
        </ul>
      `;
    };
    const validation =
      completeItem.validation.length > 0
        ? html`
            ${validationsTemplate(MessageType.Error, 'list-group-item-danger')}
            ${validationsTemplate(MessageType.Warning, 'list-group-item-warning')}
            ${validationsTemplate(MessageType.Information, 'list-group-item-info')}
          `
        : html``;

    return html`
      <div class="row">
        <div class="col-12">${validation}</div>
      </div>
    `;
  }

  async canClose(): Promise<boolean> {
    return await this.canCancelOperations();
  }

  /**
   *
   * @param targetQuoteItem the property link we are checking or updating
   * @param value the checked state of the completedItem
   * @returns the state after executing
   */
  protected quoteItemChecked(targetQuoteItem: QuoteItemContainer, value?: boolean): boolean {
    let ps = this.targetChecked.find(x => x.targetItem.item.id === targetQuoteItem.item.id);
    if (!ps) {
      ps = {
        targetItem: targetQuoteItem,
        checked: value ?? true
      };
      this.targetChecked.push(ps);
    }
    if (value != undefined) {
      ps.checked = value;
    }
    return ps.checked;
  }

  /**
   *
   * @returns true if we can close the dialog
   */
  protected async canCancelOperations(): Promise<boolean> {
    if (!this.processingInfo.processing) return true;

    if (this.processingInfo.cancelling) {
      await this.currentProcess;
      return true;
    }
    if (
      await AskConfirmation(
        tlang`All updates may not have completed. Do you want to cancel update?`,
        confirmationButtons[ConfirmationButtonType.yesNo]
      )
    ) {
      //trigger the cancellation
      this.processingInfo.cancelling = true;
      const waiting = waitPatiently(
        () => tlang`Cancelling Operations`,
        () => tlang`Please wait while the processes clean up`
      );
      try {
        //wait for the cancel to complete
        await this.currentProcess;
      } finally {
        await waiting.hideModal();
      }
      return true;
    } else return false;
  }

  protected bodyTemplate(): EventTemplate {
    const itemTemplates = this.outOfDateItemsTemplate2(this.outOfDateItems);
    const version = getSupplierVersion(this.quoteManager.quote.supplierId);
    const priceAdjComent = this.hasSupplierPriceAdjustments
      ? `
                Revalidation may result in changes that affect the price on items with supplier price adjustments 
                <webmodule-icon library="fa" name="fas-tags" class="text-base"></webmodule-icon>, if so, these adjustments will be discarded.
                You will then need to reactivate the associated support ticket to request a supplier review.`
      : ``;
    const explanation = tlang`${'ref:outOfDateFrameExplanation:markdown'}
                ## Information

                ### Current %%supplier%% version: **${version}**

                The !!frame!! shown need to be validated and re-saved because one of the following things may have occurred
                since these items were last saved:

                + The configuration service may have updated
                + %%supplier%% pricing may have been updated
                + %%quote%% properties have been edited which may effect !!frame!! or %%quote%% construction and/or pricing
                
                Please check each %%frame%% carefully after validation to ensure prices and configuration are still
                as expected. Prices may vary because of %%supplier%% price updates, but it could also be sign of an unexpected change
                in the structure of the %%frame%%.
                ${priceAdjComent}`;

    return html` <div class="alert alert-info" role="alert">${asMarkdownTemplate(explanation)}</div>
      ${itemTemplates}`;
  }

  protected getTitle(): Snippet {
    return tlang`Validate out-of-date !!frame!!`;
  }

  protected footerTemplate(): TemplateResult {
    const validateButton = !this.processingInfo.processingComplete
      ? html` <webmodule-button
          @click=${() => this.startProcessing()}
          variant="primary"
          size="small"
          ?disabled=${this.processingInfo.processing}
        >
          ${tlang`Validate`}
        </webmodule-button>`
      : html``;

    const item = this.outOfDateItems.find(x => x.item.id === this.processingInfo.currentItemToProcess?.quoteItemId);

    const pt = !item
      ? undefined
      : html`
          <div class="quote-item-attribute-update-progress-header-v2">
            <div class="title">
              ${tlang`Processing`}
              <span class="subtext quote-item-title ms-2"
                ><span class="quote-item-position me-2"
                  >${tlang`#${this.quoteManager.itemPosition(item.item.id)}`}
                </span>
                <span class="quote-item-description"> ${item.item.title} (${item.item.description} )</span>
              </span>
            </div>
            <div class="status">${this.getItemBadge(item)}</div>
          </div>
        `;
    return html`
      ${pt}
      <webmodule-button
        size="small"
        variant="default"
        @click=${() => this.hideModal()}
        ?disabled=${this.processingInfo.processing}
      >
        ${tlang`Close`}
      </webmodule-button>
      ${validateButton}
    `;
  }

  private isBuyInPriceCurrent(qic: QuoteItemContainer): boolean {
    if (this.quoteManager.priceValidation && this.quoteManager.priceValidation.length != 0) {
      return this.quoteManager.priceValidation.findIndex(x => x.id == qic.item.id) < 0;
    }

    return true;
  }

  private displayVal(main: string | null | undefined, next: string | null | undefined) {
    if (!isEmptyOrSpace(main)) return main;
    if (!isEmptyOrSpace(next)) return next;
    return '';
  }

  private getItemToProcess(qic: QuoteItemContainer): ItemToProcess {
    let item = this.processingInfo.itemsToProcess.find(x => x.quoteItemId === qic.item.id);
    if (!item) {
      item = {
        quoteItemId: qic.item.id
      };
      this.processingInfo.itemsToProcess.push(item);
    }
    return item;
  }

  private needsExtendedDisplay(completeItem: QuoteItemProcessingCompleteItem) {
    return (
      completeItem.newPrice !== completeItem.oldPrice || completeItem.validation.length > 0 || completeItem.error !== ''
    );
  }
}
