import { clone } from '../../../../../webmodule-common/other/clone';
import { customElement } from 'lit/decorators.js';
import { DevelopmentError } from '../../../../../webmodule-common/other/development-error';
import { QuoteItemFrameViewForV6 } from '../../../../quotes/v6/v6-quote-item-frame-view';
import { QuoteItemViewOptions } from '../../../../quotes/views/quote-item-view';
import { V6FranchiseeQuoteProviderData } from '../../data/franchisee-quote-provider-data';
import { V6PickerFamilyButton } from '../../../../../webmodule-common/interop/webmodule-interop';

@customElement('wm-franchiseequoteitemframeviewforv6')
export class FranchiseeQuoteItemFrameViewForV6 extends QuoteItemFrameViewForV6 {
  constructor(options: QuoteItemViewOptions, supplierSelector?: unknown) {
    super(options);

    if (supplierSelector) {
      if (supplierSelector['family'] == undefined)
        throw new DevelopmentError(`${JSON.stringify(supplierSelector)} is not a valid V6PickerFamilyButton`);
      this.framePicker = clone(supplierSelector as V6PickerFamilyButton);
    }
  }

  protected getQuoteIGUs(): unknown {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.igu ?? [];
  }

  getQuoteDefaults(): unknown {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.defaultOptions ?? [];
  }

  getGlobalAttributesMasterSet(): unknown | null {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.globalAttributesMasterSet ?? [];
  }

  saveGlobalAttributesMasterSet(options: unknown): boolean {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.globalAttributesMasterSet = options;
    return true;
  }

  getAttributeUpdatedHistory(): unknown | null {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.quoteAttributeUpdateHistory ?? [];
  }

  saveAttributeUpdatedHistory(options: unknown): boolean {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.quoteAttributeUpdateHistory = options;
    return true;
  }

  saveQuoteDefaults(options: unknown): boolean {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.defaultOptions = options;
    return true;
  }
}
