import { customElement } from 'lit/decorators.js';
import { DataCache } from '../../../webmodule-common/cache/data-cache';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { emptyAddress } from '../../../webmodule-common/other/ui/maps/map-helpers';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { EventGetReference, EventSnippet, EventTemplate, NullPromise } from '../../../webmodule-common/other/ui/events';
import { getApiFactory } from '../../api/api-injector';
import { getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import { getProjectNumberFormatted } from '../../dealer-franchisee/projects/data/project-helper-functions';
import { html, TemplateResult } from 'lit';
import { IconRefresh } from '../../../webmodule-common/other/ui/icons/icon-refresh';
import { InputCreateProject, ProjectState, ViewProjectSummary } from '../../api/dealer-api-interface-project';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { IStaleData } from '../../../webmodule-common/other/stale-data';
import { launchProject, resourceProject } from '../ui/launcher';
import { LitTableWrapper } from '../../../webmodule-common/other/ui/littable-view';
import { localDateToServer, today } from '../../../webmodule-common/other/datetime-converter';
import { PageControl, PageControlOptions, PageManager } from '../../../webmodule-common/other/ui/page-control';
import { ProjectApi } from '../../api/project-api';
import { ProjectContainer } from '../data/project-container';
import { PurchaseOrderCacheData } from '../../dealer-franchisee/cache-impl/cache-data';
import { RequestPage, ResultPaginated } from '../../../webmodule-common/other/ui/RequestPage';
import { resolveURL } from '../../../webmodule-common/other/ui/resource-resolver';
import { resourceClient } from '../../dealer-franchisee/clients/ui/launcher';
import { showError } from '../../../webmodule-common/other/ui/show-error';
import { staleIcon } from '../../../webmodule-common/other/ui/icons/icon-notification-signal';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { ViewBase } from '../../../webmodule-common/other/ui/view-base';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef
} from '../../../webmodule-common/components/src/webmodule-components';

export interface ProjectSummaryTableOptions {
  projectOwnerId: EventGetReference;
  projectStates?: ProjectState;
  clientCache: DataCacheGeneric;
  userCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  title: EventSnippet;
  pageFragment: string;
  stale?: IStaleData;
  clientId?: string | null;
}
@customElement('wm-projectsummarytable')
export class ProjectSummaryTable extends LitTableWrapper<ViewProjectSummary> {
  eventTitle: EventSnippet;
  projectApi: ProjectApi = getApiFactory().project();
  projectOwnerId: EventGetReference;
  projectStates: ProjectState;
  clientCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  userCache: DataCacheGeneric;
  filter: string | null;
  pageFragment: string;
  staleData: IStaleData;
  dataIsStale = false;
  clientId?: string | null;
  isDataLoadDelayed() {
    return true;
  }

  constructor(options: ProjectSummaryTableOptions) {
    super();
    this.staleData = options.stale ?? { exists: () => false };
    this.eventTitle = options.title;
    this.projectStates = options.projectStates ?? ProjectState.None;
    this.clientCache = options.clientCache;
    this.projectOwnerId = options.projectOwnerId;
    this.userCache = options.userCache;
    this.purchaseOrderCache = options.purchaseOrderCache;
    this.filter = null;
    this.pageFragment = options.pageFragment;
    this.clientId = options.clientId;
  }

  override updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  override enableFiltering(): boolean {
    return true;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<ViewProjectSummary>> {
    const results = await this.projectApi.browseProjectSummary({
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      statesToLoad: this.projectStates,
      filter: this.filter,
      sortField: request.sortField,
      sortAsc: request.sortAsc,
      createdById: null,
      projectOwnerId: await this.projectOwnerId(),
      clientId: this.clientId ?? null
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const clientKeys = results.projectSummary.results!.map(x => x.clientId);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userKeys = results.projectSummary.results!.map(x => x.assignedToUserId);

    //if there are any other callbacks we need to do, add them to the
    //all array which will let them run at the same time
    await Promise.all([this.clientCache.preFetch(clientKeys), this.userCache.preFetch(userKeys)]);

    return results.projectSummary;
  }

  getDefaultSortField(): string | undefined {
    return 'number';
  }

  getProjectLink(id: string, displayText: string): TemplateResult {
    return html`<a class="project-link" data-project-id=${id} href="${resolveURL(resourceProject, id)}"
      >${displayText}</a
    >`;
  }
  getClientLink(id: string, displayText: string): TemplateResult {
    return html`<a class="client-link" data-client-id=${id} href="${resolveURL(resourceClient, id)}"
      >${displayText}</a
    >`;
  }
  stale(row: ViewProjectSummary) {
    return this.staleData.exists(row.id);
  }
  getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`%%project%% No.`,
        fieldName: 'number',
        sortable: true,
        classes: 'colpx-90 colpxmax-180 project-number',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const row = item as ViewProjectSummary;
          return this.stale(row)
            ? // eslint-disable-next-line @typescript-eslint/no-base-to-string
              `${staleIcon(false)}${this.getProjectLink(row.id, getProjectNumberFormatted(row))}`
            : this.getProjectLink(row.id, getProjectNumberFormatted(row));
        }
      },
      {
        title: tlang`%%project%% Title`,
        fieldName: 'title',
        sortable: true,
        classes: 'colpx-400 project-title',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const row = item as ViewProjectSummary;
          return this.getProjectLink(row.id, row.title);
        }
      },
      {
        title: tlang`%%client%%`,
        fieldName: 'clientId',
        sortable: false,
        classes: 'colpx-200 colpxmax-360 project-client',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const row = item as ViewProjectSummary;
          return this.getClientLink(row.clientId, this.getClientDisplayValue(row.clientId));
        }
      },
      {
        title: tlang`Author`,
        fieldName: 'assignedToUserId',
        sortable: false,
        classes: 'colpx-200 colpxmax-240 project-client-name',
        displayValue: (_table: WebModuleLitTable, item: unknown) => {
          const row = item as ViewProjectSummary;
          return this.getUserDisplayValue(row.assignedToUserId);
        }
      }
    ];
  }

  getUserDisplayValue(value: string) {
    if (value == emptyGuid) return '';
    //prefetch should already have been called
    return this.userCache.getLocal(value)?.displayValue ?? '';
  }

  protected getClientDisplayValue(value: string) {
    if (value === emptyGuid) return '';
    //prefetch should already have been called
    return this.clientCache.getLocal(value)?.displayValue ?? '';
  }
}

export interface ProjectListViewOptions {
  projectOwnerId: EventGetReference;
  clientCache: DataCacheGeneric;
  contactCache: DataCacheGeneric;
  paymentProfileCache: DataCacheGeneric;
  userProfileCache: DataCacheGeneric;
  quoteCache: DataCacheGeneric;
  purchaseOrderCache: DataCache<PurchaseOrderCacheData>;
}
@customElement('wm-projectlistview')
export class ProjectListView extends ViewBase {
  tables: ProjectSummaryTable[];

  elementId: string;
  clientCache: DataCacheGeneric;
  contactCache: DataCacheGeneric;
  clientTypeCache: DataCacheGeneric;
  userProfileCache: DataCacheGeneric;
  pageControl: PageControl;
  protected staleOrders: string[] = [];
  projectOwnerId: EventGetReference;
  projectApi: ProjectApi = getApiFactory().project();
  quoteCache: DataCacheGeneric;
  purchaseOrderCache: DataCacheGeneric;
  constructor(options: ProjectListViewOptions) {
    super();
    this.elementId = `project-list-view-${getInternalId()}`;
    this.projectOwnerId = options.projectOwnerId;
    this.clientCache = options.clientCache;
    this.contactCache = options.contactCache;
    this.clientTypeCache = options.paymentProfileCache;
    this.userProfileCache = options.userProfileCache;
    this.quoteCache = options.quoteCache;

    this.purchaseOrderCache = options.purchaseOrderCache;
    this.tables = this.initializeTables();
    this.pageControl = this.createPageControl();
  }
  public async setActiveTabByHash() {
    await this.pageControl?.applyWindowHash();
  }
  afterRefresh() {
    //nothing
  }
  beforeRefresh() {
    this.staleOrders = []; //nothing
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      return this.tables.map(table => {
        const pm: PageManager = {
          caption: table.eventTitle,
          canClose: () => Promise.resolve(false),
          canLeave: () => Promise.resolve(true),
          hasDelete: () => false,
          onEnter: async () => {
            //enable data load the first time we enter the tab, but not before
            table.delayedDataLoad = false;
            // not-awaiting on purpose, for faster page switch. safe to do
            if (table.dataIsStale) table.refreshData();
          },
          content: () => {
            return table;
          },
          data: table,
          pageFragment: table.pageFragment
        };
        return pm;
      });
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: [
        {
          event: async () => {
            if (this.pageControl.activeTabIndex < this.tables.length) {
              const table = this.pageControl.activePage?.data as ProjectSummaryTable;
              this.beforeRefresh();
              await table.refreshData();
              this.tables.forEach(t => {
                if (t !== table) {
                  t.dataIsStale = true;
                }
              });
              this.afterRefresh();
              return true;
            } else return false;
          },
          caption: () => html`${new IconRefresh()}`
        }
      ],
      plusPage: {
        caption: () => tlang`Create %%project%%`,
        event: async () => await this.addProject()
      },
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  protected async createNewProject(): NullPromise<ProjectContainer> {
    const owner = await this.projectOwnerId();
    if (!(await userDataStore.singleSupplierTACApproved())) return null;
    if (!userDataStore.defaultBranch) await userDataStore.loadCoreDetails();
    if (!userDataStore.defaultBranch) {
      await showError(
        tlang`Cannot create %%project%% because you are not assigned to a %%branch%%, please contact support`
      );
      return null;
    }

    //TODO: don't default project address to franchisee for now, we'll revisit this later in task 213557
    const addressToUse = emptyAddress();

    if (!isEmptyOrSpace(owner)) {
      try {
        const inputCreateProject: InputCreateProject = {
          project: {
            title: tlang`New %%project%%`,
            budget: 0,
            id: newGuid(), //change this to use server side guid fetch
            number: 0,
            clientId: emptyGuid,
            description: null,
            recordVersion: '',
            dateCreated: localDateToServer(today()),
            state: ProjectState.Active,
            clientTypeId: emptyGuid,
            contactId: emptyGuid,
            lastModifiedDate: localDateToServer(today()),
            creationUserId: emptyGuid, //set on server side
            defaultAddress: addressToUse,
            shipToDefaultAddress: false,
            lastModifiedUserId: emptyGuid,
            shippingNotes: null,
            projectOwnerId: owner,
            assignedToUserId: emptyGuid // set on server side
          },
          numberSeed: owner,
          projectReferences: []
        };

        const result = await this.projectApi.createProject(inputCreateProject);

        if (!result) return null;

        //construct new project container for an empty new project
        return new ProjectContainer(result.project.id, result.project, result.resources, result.documents);
      } catch {
        return null;
      }
    } else {
      //owner is defined as the Branch of the user.
      await showError(
        tlang`Cannot create %%project%% because you are not assigned to a %%branch%%, please contact support`
      );
      return null;
    }
  }

  protected async addProject(): Promise<boolean> {
    const project = await this.createNewProject();
    if (project) {
      launchProject(project.projectId);
      return true;
    }
    return false;
  }

  protected createSummaryTable = (
    title: EventSnippet,
    projectState: ProjectState,
    pageFragment: string
  ): ProjectSummaryTable => {
    return new ProjectSummaryTable({
      projectStates: projectState,
      clientCache: this.clientCache,
      userCache: this.userProfileCache,
      purchaseOrderCache: this.purchaseOrderCache,
      title: title,
      projectOwnerId: this.projectOwnerId,
      pageFragment: pageFragment,
      stale: { exists: (id: string) => this.staleOrders.includes(id) }
    });
  };

  private initializeTables(): ProjectSummaryTable[] {
    return [
      this.createSummaryTable(() => tlang`Active`, ProjectState.Active, 'active'),
      this.createSummaryTable(() => tlang`Completed`, ProjectState.Completed, 'completed'),
      this.createSummaryTable(() => tlang`Cancelled`, ProjectState.Cancelled, 'cancelled')
    ];
  }

  protected template(): EventTemplate {
    return html` <div id=${this.elementId} class="page-content">${this.pageControl.ui}</div>`;
  }
}
