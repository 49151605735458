import { html, TemplateResult } from 'lit';
import { LitBaseModal } from './modal/modal-factory-lit';
import { Snippet } from '../../interop/webmodule-interop';
import { tlang } from '../language/lang';

import { createCancelButton } from './modal-footer-buttons';

export async function previewImage(src: string) {
  const d = new ImagePreview(src);
  await d.showModal();
}

class ImagePreview extends LitBaseModal {
  src: string;

  constructor(src: string) {
    super();
    this.src = src;
  }

  get borderType(): string | undefined {
    return 'basic';
  }

  get isFooterVisible(): boolean {
    return true;
  }

  footerTemplate(): TemplateResult {
    return createCancelButton(() => this.hideModal());
  }

  protected get modalClasses(): string {
    return 'modal-dialog ';
  }

  protected get modalBodyContentClasses(): string | undefined {
    return 'img-preview-content';
  }

  protected get modalSize() {
    return 'img-modal modal-fullscreen';
  }

  public bodyTemplate(): TemplateResult {
    const eventClose = () => this.hideModal();
    return html`
      <div class="img-preview">
        <img @click=${eventClose} src=${this.src} alt="Preview of the uploaded file" />
      </div>
    `;
  }

  title(): Snippet {
    return tlang`Image Preview`;
  }
}
