import { ClientViewOptions } from '../../clients/views/client-view';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { ContactViewOptions } from '../../clients/views/contact-view';
import { customElement, state } from 'lit/decorators.js';
import { disposeOf } from '../../../webmodule-common/other/dispose';
import {
  FranchiseeClientListView,
  FranchiseeClientListViewOptions
} from '../clients/views/franchisee-client-list-view';
import { FranchiseeClientView } from '../clients/views/franchisee-client-view';
import { FranchiseeContactView } from '../clients/views/franchisee-contact-view';
import { FranchiseePaymentProfileView } from '../franchisee/view/franchisee-payment-profile-view';
import { getInternalId } from '../../../webmodule-common/other/ui/databinding/databinding';
import { html } from 'lit';
import { PaymentProfileViewOptions } from '../../franchisee/view/payment-profile-view';
import { TabbedPageBase } from './helpers/page-base';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { userDataStore } from '../common/current-user-data-store';

@customElement('app-clients')
export class AppClientsPage extends TabbedPageBase {
  @state()
  clientListView: FranchiseeClientListView | null = null;
  internalId: string = getInternalId();

  constructor() {
    super();
  }

  private get elementId(): string {
    return `clients-page-${this.internalId}`;
  }

  private static clientViewFactory(options: FranchiseeClientListViewOptions) {
    //change this to a subclass which has branch details, or otherwise.. ie FranchiseeBranchQuoteListView
    return new FranchiseeClientListView(options);
  }

  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.clientListView?.ui}</div>
      </div>
    `;
  }

  meta() {
    return {
      title: tlang`!!client!!`,
      description: tlang`!!client!! page`
    };
  }

  protected async afterUserConnected() {
    this.clientListView = AppClientsPage.clientViewFactory({
      clientOwnerId: userDataStore.clientOwnerId,
      clientViewFactory: async (options: ClientViewOptions) => await constructAsync(new FranchiseeClientView(options)),
      contactViewFactory: async (options: ContactViewOptions) =>
        await constructAsync(new FranchiseeContactView(options)),
      paymentProfileFactory: async (options: PaymentProfileViewOptions) =>
        await constructAsync(new FranchiseePaymentProfileView(options))
    });
    await super.afterUserConnected();
  }
  async dispose() {
    await super.dispose();
    await disposeOf(this.clientListView);
    this.clientListView = null;
  }
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async setActiveTabByHash() {
    await this.clientListView?.setActiveTabByHash();
  }
}
